















import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import { accountRegister, accountRegisterResend } from '../../../apis/account.api';
import { SyncWithRouterQuerySimple } from '../../../utils/decorators/SyncWithRoute';
import { popupLoadings, errorPopupHandler, successPopupHandler } from '../../../utils/decorators/VueTimmer';
import { forgotPassword } from '../../../apis/password.api';

@Component
export default class ForgotPasswordConfirming extends Vue {
  
  @SyncWithRouterQuerySimple("email",{defaultValue:""})
  email: string

  @errorPopupHandler("Fail to send reset password email")
  @successPopupHandler("Send reset password email success")
  @popupLoadings("Sending reset password email")
  onResend(){
    console.log("resend", this.email)
    return forgotPassword({
      email: this.email
    })
  }

}
